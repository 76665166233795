<template>
  <div class="editor-article-container">
    <header class="header">
      <van-nav-bar
        title="编辑文章"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
        <!-- right-text="删除"
        @click-right="onClickRight" -->
      </van-nav-bar>
    </header>

    <div class="del-wrapper">
      <van-overlay :show="showDel" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p>删除</p>
            <p>确定删除该文章？</p>
            <van-grid clickable :column-num="2">
              <van-grid-item
                ><span @click="handelConfirm('')">取消</span></van-grid-item
              >
              <van-grid-item
                ><span @click="handelConfirm(articles.articleId)"
                  >确定</span
                ></van-grid-item
              >
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>

    <article class="article">
      <div class="article_info">
        <span>文章标题</span>
        <van-field
          v-model="articles.title"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入你要添加的内容"
          show-word-limit
        />
        <span>文章封面</span>
        <div class="article_img">
          <image-upload
            v-if="!loading"
            :busiId="busi_Id"
            :busiCat="busi_Cat"
            :groupId="group_Id_index"
            :isRealDeleteImg="isRealDeleteImg"
            :newBusiId="newBusiId"
          ></image-upload>
        </div>
        <span>海报图</span>
        <div class="article_img">
          <image-upload
              v-if="!loading"
              :busiId="busi_Id"
              :busiCat="`poster`"
              :groupId="`sharePosterPic`"
              :isRealDeleteImg="isRealDeleteImg"
              :newBusiId="newBusiId"
          ></image-upload>
        </div>
      </div>
      <div class="article_editor">
        <rich-editor
          v-if="!loading"
          v-model="articles.content"
          :busiId="busi_Id"
          :busiCat="busi_Cat"
          :groupId="group_Id_context"
        ></rich-editor>
      </div>
      <div class="star-and-con">
        <van-field
          label="点赞数"
          placeholder="请输入"
          clearable
          type="number"
          v-model="articles.countOfLikes"
        />
        <van-field
          label="收藏数"
          placeholder="请输入"
          clearable
          type="number"
          v-model="articles.countOfCollection"
        />
      </div>
      <div class="is-show-card">
        <div class="show-switch"><span>在我的名片显示</span><van-switch v-model="showInCard" @change="switchShowInCard" size="20px" inactive-color="#BFBFBF"/></div>
          <van-field
          label="名片排名"
          placeholder="数字越大越靠前"
          clearable
          type="number"
          v-model="articles.cardSort"
          v-if="showInCard"
        />
      </div>

      <div class="footer">
        <van-grid clickable :column-num="2" safe-area-inset-bottom>
          <van-grid-item
            ><van-button
              round
              type="primary"
              block
              color="#BFBFBF"
              @click="handleBlack"
              >返回</van-button
            ></van-grid-item
          >
          <van-grid-item
            ><van-button
              round
              type="primary"
              block
              color="#FF6E01"
              @click="handleSaveArticle"
              >保存</van-button
            ></van-grid-item
          >
        </van-grid>
      </div>
    </article>
  </div>
</template>
<script>
import RichEditor from "../../components/RichEditor.vue";
import ImageUpload from "../../components/FileUpload/ImageUpload.vue";
import { reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getArticleById } from "../../api/articleManagement";
import { deleteArticle, updateArticle } from "../../api/huokeArticle";
import { isArticleBelongUser } from "../../api/huokeArticle";
import {
  deleteArticleMana,
  updateArticleMana,
} from "../../api/articleManagement";

export default {
  components: {
    RichEditor,
    ImageUpload,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      showInCard:false,
      loading: false,
      busi_Id: route.query.id,
      busi_Cat: "article",
      group_Id_index: "articleIndexPic",
      group_Id_context: "articleContentPic",
      isRealDeleteImg: false,
      newBusiId: "",
      articleContent: "",
      showDel: false,
      articles: {
        oldId: "",
        articleId: "", //文章id
        title: "", //标题
        content: "", //内容
        countOfLikes: null, //点赞
        countOfCollection: null, //收藏
        cardSort:null,
        isCardRecommend:null,
        isMaterial:null,
      },
    });
    const switchShowInCard = (str)=>{
      console.log("开关卡关",str);
      if(str===true){
        state.articles.isCardRecommend = 1
      }else{
        state.articles.isCardRecommend = 0
      }
    }
    const handleBlack = () => {
      router.back();
    };
    const getNewArticleId = async () => {
      let res = await isArticleBelongUser(route.query.id);
      state.isRealDeleteImg = res === route.query.id;
      state.newBusiId = res;
      if (Number(route.query.type) === 1) {
        state.articles.articleId = route.query.id;
      } else {
        state.articles.articleId = res;
      }
    };

    const loadArticleInfo = async () => {
      state.loading = true;

      let data = await getArticleById(route.query.id);
      await getNewArticleId();
      state.loading = false;
      state.articles.title = data.title;
      state.articles.content = data.content;
      state.articles.countOfLikes = data.countOfLikes;
      state.articles.countOfCollection = data.countOfCollection;
      state.articles.oldId = route.query.id;
      state.articles.isCardRecommend = data.isCardRecommend;
      state.articles.cardSort = data.cardSort
      state.articles.isMaterial = data.isMaterial
      if(data.isCardRecommend===1){
         state.showInCard = true
      }else{
        state.showInCard = false
      }
    };
    loadArticleInfo();

    const handleSaveArticle = async () => {
      let params = state.articles
      if (Number(route.query.type) === 1) {
        let result = await updateArticleMana(params);
        if (result) {
          router.push({
            path: "/articleManagement/articleDetails",
            query: {
              id: state.articles.articleId,
              twoType: route.query.twoType,
              oneType: route.query.oneType,
            },
          });
        }
      } else {
        if (state.isRealDeleteImg) {
          let result = await updateArticle(params);
          console.log("修改文章：", result);
        } else {
          let result = await updateArticle(params);
          console.log("修改文章：", result);
        }
        router.push({
          path: "/articleVisitors/visitorDetails",
          query: {
            id: state.articles.articleId,
            myType: route.query.myType,
            twoType: route.query.twoType,
          },
        });
      }
    };

    const onClickRight = () => {
      state.showDel = true;
    };
    //确认删除
    const handelConfirm = async (artcleId) => {
      state.showDel = !state.showDel;
      if (artcleId) {
        if (Number(route.query.type) === 1) {
          let result = await deleteArticleMana(artcleId);
          if (result) {
            router.push({
              name: "articleManagement",
              params: { oneType: route.query.type },
            });
          }
        } else {
          let result = await deleteArticle(artcleId);
          if (result) {
            router.push({
              name: "articleVisitors",
              params: { oneType: route.query.myType },
            });
          }
        }
      }
    };
    return {
      ...toRefs(state),
      handelConfirm,
      handleBlack,
      handleSaveArticle,
      onClickRight,
      switchShowInCard,
    };
  },
};
</script>
<style lang="scss">
.editor-article-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;
  //遮罩层
  //遮罩层
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
        }
      }
    }
  }

  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .article {
    flex: 1;
    overflow-x: hidden;
    .article_info {
      padding: 17px;
      text-align: left;
      border-radius: 5px;
      > span {
        color: rgba(0, 0, 0, 0.84);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
      }
      > span:nth-child(3) {
        margin-top: 30px;
      }
    }
    .article_editor {
      //text-align: left;
      img{
        max-width: 100%;
        vertical-align: middle;
      }
      p{
        //margin: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

      }
      text-align: left;
      font-size: 14px;
    }
    .star-and-con {
      padding: 0 16px;

      .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 600;
        color: black;
      }
    }
    .is-show-card{

      padding: 0 16px;
      .show-switch{
        color: black;
        font-size: 14px;
        font-weight: 600;
        padding: 0 16px;
        height: 44px;
        margin-top: 8px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
       .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 600;
        color: black;
      }

    }
    .button_confirm {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      > div {
        width: 160px;
        height: 44px;
        color: #fff;
        font-size: 14px;
        border-radius: 8px;
        text-align: center;
        line-height: 44px;
        background-color: #bfbfbf;
      }
      .button_confirm_save {
        background: linear-gradient(0deg, #ff6e01, #ff6e01);
      }
    }
    .footer {
      .van-button--round {
        border-radius: 8px;
        height: 44px;
        width: 160px;
      }
      .van-hairline {
        background-color: #f7f7f7;
      }
    }
  }
}
</style>
